import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Sidebar } from './Dashboards/Organizer/Sidebar'
import { Header } from './Dashboards/Organizer/Header'
import { TopStatistics } from './Dashboards/Organizer/TopStatistics'
import { MyPools } from './Dashboards/Organizer/MyPools'
import { History } from './Dashboards/Organizer/History'
import { setPools } from '../store/poolsReducer'
import { TopAlert } from './Dashboards/Organizer/TopAlert'


export default function Component({ notice, userSignedIn, currentUser, csrfToken, matches, ...props }) {
  const pools = useSelector(state => state.pools.pools)
  const dispatch = useDispatch()

  const [selectedPoolIndex, setSelectedPoolIndex] = useState(0)
  const [myPools, setMyPools] = useState([])

  const averageParticipants = myPools.reduce((acc, pool) => acc + pool?.participants?.length, 0) / myPools.length;

  const fetchPools = async () => {
    try {
      const response = await fetch('/api/pools');
      if (!response.ok) {
        throw new Error(`Error: ${response.statusText}`);
      }
      const data = await response.json();
      console.log('Fetched pools:', data);
      
      dispatch(setPools(data));
    } catch (error) {
      console.error('Error fetching pools:', error);
    }
  };

  useEffect(() => {
    fetchPools();
  }, []);


  useEffect(() => {
    setMyPools(pools.filter(pool => pool.user_id === currentUser.id))
  }, [pools])

  return (
    <div style={{
      display: 'flex',
      height: '100vh',
      backgroundColor: '#F3F4F6',
      borderRadius: '0.5rem'
    }}>
      <Sidebar />
      <div style={{
        flex: 1,
        padding: '2rem',
        overflowY: 'auto'
      }}>
        <Header currentUser={currentUser} />
        {/* {notice && (
          <div style={{
            backgroundColor: '#E0F2FE',
            borderLeft: '4px solid #3B82F6',
            color: '#1E40AF',
            padding: '1rem',
            marginBottom: '2rem'
          }} role="alert">
            <p>{notice}</p>
          </div>
        )} */}
        <TopAlert notice={'Estamos en fase beta, para cualquier reporte de error o sugerencia envía correo a hi@futpools.com'} />
        <TopStatistics subscribers={currentUser.referral_count} pools={myPools} averageParticipants={averageParticipants} />
        <div style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',
          gap: '2rem'
        }}>
          <MyPools myPools={myPools} setSelectedPoolIndex={setSelectedPoolIndex} handleShare={handleSharePool} />
          <History myPools={myPools} />
        </div>
      </div>
    </div>
  )

  function generateShareLink(poolId) {
    return `${window.location.origin}/pools/${poolId}`;
  }

  function handleSharePool(poolId) {
    const shareLink = generateShareLink(poolId);
    // TODO: Implement social sharing logic here
    console.log(`Share this link: ${shareLink}`);
  }
}
