import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  pools: [],
  matches: [],
  leagues: [],
};

const poolsSlice = createSlice({
  name: 'pools',
  initialState,
  reducers: {
    setPools(state, action) {
      state.pools = Array.isArray(action.payload) ? action.payload : [];
    },
    setPool(state, action) {
      console.log('Reducer setPool called with payload:', action.payload); 
      const pool = action.payload;
      if (pool) {
        console.log("Tenemos pool en reducer: ", pool);
        const existingPoolIndex = state.pools.findIndex(p => p.id === pool.id);
        if (existingPoolIndex !== -1) {
          state.pools[existingPoolIndex] = pool;
        } else {
          state.pools.push(pool);
        }
    
        // Asigna los matches directamente si están presentes en el payload
        if (pool.matches) {
          state.matches = pool.matches;
          console.log('Matches after setting in state:', state.matches);
        }
      }
    },    
    setMatches(state, action) {
      console.log('Reducer setMatches called with payload:', action.payload);
      state.matches = Array.isArray(action.payload) ? action.payload : [];
    },
    setLeagues(state, action) {
      console.log('Reducer setLeagues called with payload:', action.payload);
      if (!Array.isArray(action.payload)) {
        console.error('Expected leagues to be an array but received:', action.payload);
      }
      state.leagues = action.payload;
    },
    addPool(state, action) {
      console.log('Reducer addPool called with payload:', action.payload);
      state.pools.push(action.payload);
    },
    // Otros reducers si es necesario
  },
});

export const { setPools, setPool, setMatches, setLeagues, addPool } = poolsSlice.actions;
export default poolsSlice.reducer;