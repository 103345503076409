import React from 'react'
import '../../../stylesheets/TopAlertStyles.css'

export const TopAlert = ({ notice }) => {
  return (
    <>
      <div className="top-alert">
        <p>{ notice }</p>
      </div>
    </>
  )
}
