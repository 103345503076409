import React from 'react'

export const Sidebar = () => {
  const IconUsers = () => <span className="icon">👥</span>
  const IconSettings = () => <span className="icon">⚙️</span>
  const IconHelpCircle = () => <span className="icon">❓</span>
  const IconLogOut = () => <span className="icon">🚪</span>
  
  return (
    <div style={{
      width: '16rem',
      backgroundColor: 'white',
      padding: '1.5rem',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      borderRadius: '0.5rem',
    }}>
      <div>
        <h1 style={{
          fontSize: '1.5rem',
          fontWeight: 'bold',
          marginBottom: '2rem'
        }}>FutPools.com</h1>
        <nav style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
          {/* <a href="#" style={{
            display: 'flex',
            alignItems: 'center',
            color: '#4B5563',
            textDecoration: 'none'
          }}>
            <IconHome />
            <span style={{marginLeft: '0.5rem'}}>Home</span>
          </a> */}
          {/* <a href="#" style={{
            display: 'flex',
            alignItems: 'center',
            color: '#4B5563',
            textDecoration: 'none'
          }}>
            <IconFolderOpen />
            <span style={{marginLeft: '0.5rem'}}>Qunielas</span>
          </a> */}
          {/* <a href="#" style={{
            display: 'flex',
            alignItems: 'center',
            color: '#4B5563',
            textDecoration: 'none'
          }}>
            <IconUsers />
            <span style={{marginLeft: '0.5rem'}}>Suscriptores</span>
          </a>
          <a href="#" style={{
            display: 'flex',
            alignItems: 'center',
            color: '#4B5563',
            textDecoration: 'none'
          }}>
            <IconSettings />
            <span style={{marginLeft: '0.5rem'}}>Ajustes</span>
          </a> */}
        </nav>
      </div>
      <div style={{display: 'flex', flexDirection: 'column', gap: '1rem'}}>
        {/* <button style={{
          width: '100%',
          backgroundColor: '#3B82F6',
          color: 'white',
          padding: '0.5rem',
          borderRadius: '0.375rem',
          border: 'none',
          cursor: 'pointer'
        }}>
          Upgrade to Pro 🔥
        </button>
        <a href="#" style={{
          display: 'flex',
          alignItems: 'center',
          color: '#4B5563',
          textDecoration: 'none'
        }}>
          <IconHelpCircle />
          <span style={{marginLeft: '0.5rem'}}>Help & Information</span>
        </a>
        <a href="#" style={{
          display: 'flex',
          alignItems: 'center',
          color: '#4B5563',
          textDecoration: 'none'
        }}>
          <IconLogOut />
          <span style={{marginLeft: '0.5rem'}}>Log out</span>
        </a> */}
      </div>
    </div>
  )
}
