import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  FacebookShareButton,
  FacebookIcon,
  TwitterShareButton,
  TwitterIcon,
  WhatsappShareButton,
  WhatsappIcon,
} from 'react-share';

export const MyPools = ({ myPools, setSelectedPoolIndex, ...props }) => {
  const IconMessageCircle = () => <span className="icon">💬</span>;
  const IconPlus = () => <span className="icon">➕</span>;
  const [shareUrl, setShareUrl] = useState([]);

  // Ordenar las pools por fecha de creación (suponiendo que tienen un campo `created_at`)
  const sortedPools = myPools.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  useEffect(() => {
    if (myPools.length > 0) {
      const urls = myPools.map(pool => `${window.location.origin}/pools/${pool.id}`);
      setShareUrl(urls);
    }
  }, [myPools]);

  // Tomar las últimas 5 pools
  const latestPools = sortedPools.filter(pool => !pool.isFinished).slice(0, 5);

  return (
    <div style={{
      display: 'flex',
      flexDirection: 'column',
      gap: '1rem',
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      padding: '1rem'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem'
      }}>
        <h3 style={{fontSize: '1.25rem', fontWeight: '600', margin: '0'}}>Mis Quinielas</h3>
        {
          !myPools.isFinished && myPools.length > 0 && (
            <span style={{color: 'rgb(134 134 134)'}}>{ !myPools.isFinished && myPools.length} quinielas</span>
          )
        }
      </div>
      <Link to="/pools/new"
        style={{
          marginTop: '1rem',
          width: '90%',
          backgroundColor: '#3B82F6',
          color: 'white',
          padding: '0.5rem',
          borderRadius: '0.375rem',
          border: 'none',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          textDecoration: 'none',
          fontSize: '0.875rem',
          marginLeft: 'auto',
          marginRight: 'auto'
        }}
      >
        <IconPlus />
        <span style={{marginLeft: '0.5rem'}}>Crear nueva Quiniela</span>
      </Link>
      {latestPools.length > 0 && !latestPools.isFinished ? (
        <ul style={{display: 'flex', flexDirection: 'column', gap: '1rem', padding: '0'}}>
          {latestPools.map((pool, index) => (
            <li key={index} style={{ display: 'flex', alignItems: 'center', backgroundColor: '#f6f6f6', borderRadius: '5px', padding: '10px' }}>
              <a href={`/pools/${pool.id}`} style={{ flex: 1, color: 'black', textDecoration: 'none' }}>
                <div style={{display: 'flex', alignItems: 'center', gap: '0.75rem'}}>
                  <div>
                    <p style={{fontWeight: '500'}}>{pool.title || `Quiniela ${index + 1}`}</p>
                    <p style={{fontSize: '0.875rem', color: '#6B7280'}}>
                      Descripción: {pool.description || 'Sin descripción'}
                    </p>
                    <p style={{fontSize: '0.875rem', color: '#6B7280'}}>
                      Premio: {pool.prize || 'Sin premio'}
                    </p>
                  </div>
                  <div style={{marginTop: '1rem'}}>
                    { pool?.participants?.length > 0 && <h4 style={{fontWeight: '500', marginBottom: '0.5rem'}}>Participantes:</h4> }
                    {pool?.participants?.length > 0 ? (
                      <div style={{display: 'flex', marginLeft: '0.5rem'}}>
                        {pool?.participants?.map((participant, index) => (
                          <img key={index} src={participant.profile_picture_url || `/placeholder.svg?height=40&width=40`} alt={`${participant.first_name} ${participant.last_name}`} style={{
                            width: '2rem',
                            height: '2rem',
                            borderRadius: '50%',
                            border: '2px solid white',
                            marginLeft: index > 0 ? '-0.5rem' : '0'
                          }} />
                        ))}
                      </div>
                    ) : (
                      <p>Aún no hay participantes</p>
                    )}
                  </div>
                </div>
              </a>
              <div>
                {shareUrl[index] && (
                  <>
                    <FacebookShareButton url={shareUrl[index]} quote={`¡Únete a mi quiniela y gana ${pool.prize}!`}>
                      <FacebookIcon size={32} round />
                    </FacebookShareButton>
                    <TwitterShareButton url={shareUrl[index]} title={`¡Únete a mi quiniela y gana ${pool.prize}!`}>
                      <TwitterIcon size={32} round />
                    </TwitterShareButton>

                    <WhatsappShareButton url={shareUrl[index]} title={`¡Únete a mi quiniela y gana ${pool.prize}!`}>
                      <WhatsappIcon size={32} round />
                    </WhatsappShareButton>
                  </>
                )}
              </div>
            </li>
          ))}
        </ul>
      ) : (
        <p style={{color: '#6B7280'}}>No haz creado ninguna Quiniela aún...</p>
      )}
    </div>
  )
}
