import React from 'react'

export const History = ({ myPools }) => {
  const IconSend = () => <span className="icon">📤</span>

  // Ordenar las pools por fecha de creación (suponiendo que tienen un campo `created_at`)
  const sortedPools = myPools.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));

  // Tomar las últimas 5 pools
  const latestFinishedPools = sortedPools.filter(pool => pool.isFinished).slice(0, 5);

  return (
    <div style={{
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      padding: '1rem'
    }}>
      <h3 style={{fontSize: '1.25rem', fontWeight: '600', margin: '0'}}>
        Historial
      </h3>
      {latestFinishedPools.length > 0 ? (
        latestFinishedPools.map(pool => (
          <div key={pool.id} style={{
            backgroundColor: 'white',
            borderRadius: '0.5rem',
            padding: '1.5rem',
            marginBottom: '2rem'
          }}>
            <h3 style={{fontSize: '1.25rem', fontWeight: '600', marginBottom: '1rem'}}>
              {pool.title}
            </h3>
            <p style={{color: '#4B5563', marginBottom: '1rem'}}>
              {pool.description}
            </p>
            <p style={{color: '#4B5563', marginBottom: '1rem'}}>
              Prize: {pool.prize}
            </p>
            {/* <div style={{display: 'flex', gap: '1rem'}}>
              <button style={{
                backgroundColor: '#3B82F6',
                color: 'white',
                padding: '0.5rem 1rem',
                borderRadius: '0.375rem',
                border: 'none',
                cursor: 'pointer'
              }}>Administrar</button>
              <button style={{
                backgroundColor: '#E5E7EB',
                color: '#374151',
                padding: '0.5rem 1rem',
                borderRadius: '0.375rem',
                border: 'none',
                cursor: 'pointer'
              }}>Ver Participantes</button>
            </div> */}
            <div style={{marginTop: '1rem'}}>
              <h4 style={{ fontWeight: '500', marginBottom: '0.5rem' }}>Participantes</h4>
              {pool.participants && pool.participants.length > 0 ? (
                <div style={{ display: 'flex', marginLeft: '0.5rem' }}>
                  {pool.participants.map((participant, index) => (
                    <img
                      key={index}
                      src={participant.profile_picture_url || `/placeholder.svg?height=40&width=40`}
                      alt={`${participant.first_name} ${participant.last_name}`}
                      style={{
                        width: '2rem',
                        height: '2rem',
                        borderRadius: '50%',
                        border: '2px solid white',
                        marginLeft: index > 0 ? '-0.5rem' : '0',
                      }}
                    />
                  ))}
                </div>
              ) : (
                <p>Aún no hay participantes</p>
              )}
            </div>
          </div>
        ))
      ) : (
        <p>No tienes historial de Quinielas</p>
      )}
    </div>
  )
}
