import React, { useState, useEffect } from 'react';
import '../stylesheets/RegistrationForm.css';

const RegistrationForm = () => {
  // Extract props from the HTML
  const rootElement = document.getElementById('react-registration-form');
  const props = JSON.parse(rootElement.getAttribute('data-props'));

  const { csrfToken } = props;
  console.log('CSRF Token:', csrfToken);

  // States for the form data
  const [formData, setFormData] = useState({
    email: '',
    username: '',
    first_name: '',
    last_name: '',
    phone: '',
    profile_picture: null,
    password: '',
    password_confirmation: '',
    referral_code: '',
    organizer: false,
    brand_name: '',
    brand_logo: null
  });

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    if (type === 'checkbox') {
      setFormData({ ...formData, [name]: checked });
    } else if (type === 'file') {
      setFormData({ ...formData, [name]: e.target.files[0] });
    } else {
      setFormData({ ...formData, [name]: value });
    }
  };

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();

    console.log('Form data:', formData);

    const formDataObj = new FormData();
    Object.keys(formData).forEach((key) => {
      formDataObj.append(key, formData[key]);
    });

    console.log([...formDataObj.entries()]);

    fetch('/users/sign_up', {
      method: 'POST',
      body: formDataObj,
      headers: {
        'X-CSRF-Token': csrfToken,
      },
    })
    .then(response => {
      if (!response.ok) {
        return response.text().then(text => {
          console.error('Error al enviar el formulario:', text);
        });
      } else {
        return response.json();
      }
    })
    .then(data => {
      console.log('Formulario enviado exitosamente:', data);
    })
    .catch((error) => {
      console.error('Error al enviar el formulario:', error);
    });
  };

  return (
    <div className="auth-container-register">
      <h2 className="auth-title-register">Registro</h2>
      <form onSubmit={handleSubmit} encType="multipart/form-data">
        <div className="auth-field-register">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>
        <div className="auth-field-register">
          <label>Email:</label>
          <input type="email" name="email" value={formData.email} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Nombre de usuario:</label>
          <input type="text" name="username" value={formData.username} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Nombre:</label>
          <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Apellido:</label>
          <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Teléfono:</label>
          <input type="tel" name="phone" value={formData.phone} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Foto de perfil:</label>
          <input type="file" name="profile_picture" onChange={handleChange} />
        </div>

        <div className="auth-field-register">
          <label>Contraseña:</label>
          <input type="password" name="password" value={formData.password} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Confirmar contraseña:</label>
          <input type="password" name="password_confirmation" value={formData.password_confirmation} onChange={handleChange} required />
        </div>

        <div className="auth-field-register">
          <label>Código de Referido (Opcional):</label>
          <input type="text" name="referral_code" value={formData.referral_code} onChange={handleChange} />
        </div>

        <div className="auth-field-register switch-label-register">
          <label>¿Organizas quinielas?</label>
          <label className="switch-register">
            <input type="checkbox" name="organizer" checked={formData.organizer} onChange={handleChange} />
            <span className="slider-register"></span>
          </label>
        </div>

        {/* Campos adicionales si es organizador */}
        {formData.organizer && (
          <>
            <div className="auth-field-register">
              <label>Nombre de tu marca de quinielas:</label>
              <input type="text" name="brand_name" value={formData.brand_name} onChange={handleChange} required />
            </div>

            <div className="auth-field-register">
              <label>Logo de tu marca:</label>
              <input type="file" name="brand_logo" onChange={handleChange} />
            </div>
          </>
        )}
        <div className="auth-actions-register">
          <input type="submit" value="Registrar" />
        </div>
      </form>
    </div>
  );
};

export default RegistrationForm;
