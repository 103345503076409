import React from 'react'

export const Header = ({ currentUser }) => {
  return (
    <header style={{
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '2rem'
    }}>
      <div style={{display: 'flex', alignItems: 'flex-start', gap: '1rem'}}>
        <img 
          src={currentUser?.profile_picture_url || <span className="icon">{currentUser?.first_name[0]}</span>}
          alt={currentUser?.first_name || 'User'} 
          style={{width: '2.5rem', height: '2.5rem', borderRadius: '50%'}}
        />
        <div>
          <h2 style={{fontWeight: '600', margin: '0'}}>
            {currentUser ? `${currentUser.brand_name}` : 'Organizador de Quinielas'}
          </h2>
          <p style={{fontSize: '0.875rem', color: '#6B7280', margin: '0'}}>
          {currentUser ? `${currentUser.first_name} ${currentUser.last_name}` : 'Guest User'}
          </p>
        </div>
      </div>
      {/* Top Right Icons */}
      {/* <div style={{display: 'flex', alignItems: 'center', gap: '1rem'}}>
        <IconSearch />
        <IconBell />
        <IconMessageCircle />
      </div> */}
    </header>
  )
}
