import React from 'react'

export const TopStatistics = ({ subscribers, pools, averageParticipants }) => {
  return (
    <div style={{
      backgroundColor: 'white',
      borderRadius: '0.5rem',
      padding: '1rem',
      marginBottom: '1rem',
      textAlign: 'center'
    }}>
      <div style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '1rem'
      }}>
        <h3 style={{fontSize: '1.25rem', fontWeight: '600', margin: '0'}}>
          Tus Estadísticas
        </h3>
      </div>
      <div style={{
        display: 'grid',
        gridTemplateColumns: 'repeat(3, 1fr)',
        gap: '1rem'
      }}>
        <div style={{
          backgroundColor: '#F3F4F6',
          color: '#374151',
          padding: '1.5rem',
          borderRadius: '0.5rem'
        }}>
          <h4 style={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>
            Suscriptores
          </h4>
          <p style={{fontSize: '1.5rem', fontWeight: '600'}}>
            { subscribers }
          </p>
        </div>
        <div style={{
          backgroundColor: '#F3F4F6',
          color: '#374151',
          padding: '1.5rem',
          borderRadius: '0.5rem'
        }}>
          <h4 style={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>
            Quinielas creadas
          </h4>
          <p style={{fontSize: '1.5rem', fontWeight: '600'}}>
            { pools.length }
          </p>
        </div>
        <div style={{
          backgroundColor: '#F3F4F6',
          color: '#374151',
          padding: '1.5rem',
          borderRadius: '0.5rem'
        }}>
          <h4 style={{fontSize: '1rem', fontWeight: '600', marginBottom: '0.5rem'}}>
            Prom. de Participantes
          </h4>
          <p style={{fontSize: '1.5rem', fontWeight: '600'}}>
            { averageParticipants || 0 }
          </p>
        </div>
      </div>
    </div>
  )
}

export default TopStatistics
